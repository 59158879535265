import React from "reactn";
import GoogleMapReact from "google-map-react";
import FindMapMarker from "../find/FindMapMarker";
import { ReactBnbGallery } from "react-bnb-gallery";
import "react-bnb-gallery/dist/style.css";

class RightColumn extends React.PureComponent {
  constructor() {
    super();
    this.state = {
      galleryShown: false
    };
    this.openGallery = this.openGallery.bind(this);
  }

  componentWillReceiveProps(props) {
    this.setState({
      galleryShown: props.galleryOpen
    });
  }
  openGallery() {
    if (this.state.galleryShown) this.props.galleryClose();
    this.setState({
      galleryShown: !this.state.galleryShown
    });
  }

  render() {
    const vals = this.global.school;
    return (
      <div className="col-lg-2" id="rightside">
        <ReactBnbGallery
          show={this.state.galleryShown}
          photos={vals.photos.map(
            g => `${process.env.REACT_APP_DS_FILES_S3}/${g}`
          )}
          onClose={this.openGallery}
        />
        <div className="rightside">
          <div
            style={{
              verticalAlign: "middle",
              fontSize: "1rem",
              width: "100%",
              maxWidth: "350px",
              margin: "auto",
              borderBottom: "1px solid rgba(35,31,32,.25)",
              paddingBottom: "50px"
            }}
          >
            <h2>GALLERY</h2>
            <div className="d-flex" style={{ flexWrap: "wrap" }}>
              {vals.photos.map((g, i) => (
                <div className="gal-image-cont" key={i}>
                  <img
                    className="gal-image"
                    src={`${process.env.REACT_APP_DS_FILES_S3}/${g}`}
                    alt=""
                    onClick={this.openGallery}
                  />
                </div>
              ))}
            </div>
          </div>

          <div className="location">
            <h2>LOCATION</h2>
            <div className="adress">
              {vals.city}, {vals.province}
              <br />
              <sub>Near: {vals.majorIntersection}</sub>
            </div>
            <div className="school-page-rail-Map">
              <div
                style={{
                  width: "100%",
                  height: 250,
                  overflow: "hidden",
                  borderRadius: 5
                }}
              >
                <GoogleMapReact
                  bootstrapURLKeys={{
                    key: "AIzaSyDVNuWNguQz4vB25OdDpztwxE3QR_CzG0g"
                  }}
                  defaultZoom={12}
                  defaultCenter={{
                    lat: Number(vals.lat),
                    lng: Number(vals.lng)
                  }}
                  options={{
                    fullscreenControl: false,
                    scaleControl: false,
                    zoomControl: false
                  }}
                >
                  <FindMapMarker
                    lat={Number(vals.lat)}
                    lng={Number(vals.lng)}
                  />
                </GoogleMapReact>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default RightColumn;
