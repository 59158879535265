import React from "reactn";
import { Link } from "react-router-dom";
import {
  Outdoors,
  Faith,
  Arts,
  Mixed,
  Athletics,
  STEM,
  Theatre,
  Creative,
  Dance,
  Exercise,
  SpecialNeeds,
  LanguageCulture,
  Drama,
  DaycarePreschool,
  Camps,
  Music,
  Cooking,
  AfterSchool,
  Academics,
  Leadership,
  LifeSkills,
  Playgrounds,
  Tutoring,
  ServiceVolunteer,
  FacilitiesRec
} from "../../../assets";
class FindMapMarkerInfoWindow extends React.Component {
  render() {
    const infoWindowStyle = {
      position: "relative",
      bottom: 150,
      left: "-45px",
      width: 200,
      backgroundColor: "white",
      borderRadius: "10px",
      fontSize: 14,
      zIndex: 100
    };
    const photoMatch = {
      Outdoors: Outdoors,
      Faith: Faith,
      Arts: Arts,
      Music: Music,
      Mixed: Mixed,
      Athletics: Athletics,
      STEM: STEM,
      Theatre: Theatre,
      Creative: Creative,
      Dance: Dance,
      Leadership: Leadership,
      "Life Skills": LifeSkills,
      Exercise: Exercise,
      "After School": AfterSchool,
      "Before & After School": AfterSchool,
      Academics,
      "Special Needs": SpecialNeeds,
      Drama: Drama,
      Camps,
      Cooking,
      "Daycare / Preschool": DaycarePreschool,
      "Language / Culture": LanguageCulture,
      Playgrounds,
      Tutoring,
      "Service Volunteer": ServiceVolunteer,
      "Facilities (Rec Centre)": FacilitiesRec
    };

    const { school } = this.props;

    const partnerProgramImage = school.image
      ? photoMatch[school.image]
      : school.marketplace_image
      ? `${process.env.REACT_APP_DS_FILES_S3}/${school.marketplace_image}`
      : photoMatch[school.classification];
    const homeDayCareImage = `${process.env.REACT_APP_DS_FILES_S3}/${school.programCoverPhoto}`;

    return (
      <div style={infoWindowStyle}>
        <Link
          to={
            school.slug
              ? school.clickable
                ? `/programs/${school.slug}`
                : school.isLive
                ? `/kids/${school.slug}`
                : (school.progress !== 0 && school.progress !== 1 && school.progress !== 2 && school.progress !== 4 && school.progress !== 5) && `/schools/${school.slug}`
              : `#/`
          }
          onClick={() => {
            if (!school.isLive || !school.slug) {
              this.setGlobal({ openVerificationModal: true });
            } else {
              window.scrollTo(0, 0);
            }
          }}
        >
          <div className="row">
            <div className="col-md-12 no-padding flex">
              <div className="thumbnail no-padding no-margin pop-up-image">
                <img
                  className="style_image pop-up-image"
                  src={
                    partnerProgramImage || homeDayCareImage
                    // school.image
                    //   ? photoMatch[school.image] : school.marketplace_image ? `${process.env.REACT_APP_DS_FILES_S3}/${school.marketplace_image}` : photoMatch[school.classification]
                    //   `${process.env.REACT_APP_DS_FILES_S3}/${school.image}`
                    // : `${process.env.REACT_APP_DS_FILES_S3}/${school.programCoverPhoto}`
                  }
                  alt={school.programName || school.organization}
                />
              </div>
              <div className="caption">
                <h5>
                  {school.programName ||
                    (school.program_name.length > 35
                      ? school.program_name.substr(0, 32) + "..."
                      : school.program_name)}
                </h5>
                <div>
                  {school.majorIntersection ||
                    (school.intersection && school.intersection.length > 52
                      ? school.intersection.substr(0, 55) + "..."
                      : school.intersection)}
                </div>
              </div>
            </div>
          </div>
        </Link>
      </div>
    );
  }
}

export default FindMapMarkerInfoWindow;
