import React, { lazy, Suspense } from "reactn";
import { Switch, Router, Route } from "react-router-dom";
import history from "./modules/history";
import ReactGA from "react-ga";
import AuthRoute from "./routes/authRoute";
import AuthRouteParent from "./routes/authRouteParent";
import RoutePublic from "./routes/routePublic";
import Educator from "./pages/front/educator";
// import Search from "./pages/front/search";
import { CometChat } from "@cometchat-pro/chat";
import PartnersLogin from "./pages/front/login_partner";
import NotFound from "./pages/notFound";
import Privacy from "./pages/front/privacy";
import LearnMore from "./pages/front/demo";
import LoaderSquares from "./components/UI/Loader/LoaderSquares";
import { ToastContainer } from "react-toastify";
import KidsPreview from "./pages/front/kidspreview";
import PreviewPartner from "./pages/front/previewPartner";
import ProgramIframe from "./pages/front/program_iframe";
import SpecificProgram from "./pages/front/partnerSpecificProgram";
import becomeAnInstructor from "./pages/front/becomeAnInstructor";
import SpecificEvent from "./pages/front/partnerSpecificEvent";
import SpecificParty from "./pages/front/partnerSpecificParty";
import SpecificOnline from "./pages/front/partnerSpecificOnline";
import SpecificNews from "./pages/front/partnerNewsUpdates";
import SpecificMembership from "./pages/front/partnerSpecificMembership";
import ConsoleRoute from "./routes/consoleRoute";
import PublicOrgUpdate from "./pages/partners/profile/Profile";
import ScrollToTop from "./ScrollToTop";
import "./assets/css/all.min.css";
import "./assets/css/application.css";
import "./assets/css/parent_css.css";
import "./assets/css/sb-admin-2.css";
import "react-toastify/dist/ReactToastify.css";
import "react-phone-number-input/style.css";
import Terms from "./pages/front/terms";
import SpecificNewsUpdates from "./pages/front/partnerNewsUpdates";
import PartnerPromotionsDetail from "./pages/front/PartnerPromotions/PartnerPromotionsDetail";
import { Client as Styletron } from "styletron-engine-atomic";
import { Provider as StyletronProvider } from "styletron-react";
import { LightTheme, BaseProvider } from "baseui";

// import OrderReceipt from "./pages/parents/OrderReceipt/OrderReceipt";
// import CustomReceipt from "./pages/parents/OrderReceipt/CustomReceipt";
// import Search from "./pages/front/search";
import AppErrorBoundary from "./AppErrorBoundary";

// import AdminLayout from "./components/admin/AdminLayout";
const AdminLayout = lazy(() =>
  lazyRetry(() => import("./components/admin/AdminLayout"))
);
// import ParentLayout from "./components/parents/ParentLayout";
const ParentLayout = lazy(() =>
  lazyRetry(() => import("./components/parents/ParentLayout"))
);
// import PartnerLayout from "./components/partner/PartnerLayout";
const PartnerLayout = lazy(() =>
  lazyRetry(() => import("./components/partner/PartnerLayout"))
);
//import EducatorLayout from "./components/educator/EducatorLayout";
const EducatorLayout = lazy(() =>
  lazyRetry(() => import("./components/educator/EducatorLayout"))
);
// import InvoiceCheckoutPublic from "./pages/parents/InvoiceCheckout/InvoiceCheckoutPublic";
const InvoiceCheckoutPublic = lazy(() =>
  lazyRetry(() =>
    import("./pages/parents/InvoiceCheckout/InvoiceCheckoutPublic")
  )
);
// import Login from "./pages/front/login";
const Login = lazy(() => lazyRetry(() => import("./pages/front/login")));
// import FormIframeLoader from "./pages/front/form_iframe_loader";
const FormIframeLoader = lazy(() =>
  lazyRetry(() => import("./pages/front/form_iframe_loader"))
);
// import FormPage from "./pages/front/formPage"
const FormPage = lazy(() => lazyRetry(() => import("./pages/front/formPage")));
// import Signup from "./pages/front/signup";
const Signup = lazy(() => lazyRetry(() => import("./pages/front/signup")));
// import ForgotPassword from "./pages/front/forgotPassword";
const ForgotPassword = lazy(() =>
  lazyRetry(() => import("./pages/front/forgotPassword"))
);
// import ResetPassword from "./pages/front/resetPassword";
const ResetPassword = lazy(() =>
  lazyRetry(() => import("./pages/front/resetPassword"))
);
// import Partner from "./pages/front/partner";
const Partner = lazy(() => lazyRetry(() => import("./pages/front/partner")));
// import Home from "./pages/front/home";
const Home = lazy(() => lazyRetry(() => import("./pages/front/home")));
// import About from "./pages/front/about";
const About = lazy(() => lazyRetry(() => import("./pages/front/about")));

const Cart = lazy(() => lazyRetry(() => import("./pages/parents/CartPage")));
const CartCheckout = lazy(() =>
  lazyRetry(() => import("./pages/front/CartCheckout"))
);
const OrderReceipt = lazy(() =>
  lazyRetry(() => import("./pages/parents/OrderReceipt/OrderReceipt"))
);
const CustomReceipt = lazy(() =>
  lazyRetry(() => import("./pages/parents/OrderReceipt/CustomReceipt"))
);
const Search = lazy(() => lazyRetry(() => import("./pages/front/search")));

const engine = new Styletron();

// a function to retry loading a chunk to avoid chunk load error for out of date code
const lazyRetry = function (componentImport) {
  return new Promise((resolve, reject) => {
    // check if the window has already been refreshed
    // const hasRefreshed = JSON.parse(
    //   window.sessionStorage.getItem("retry-lazy-refreshed") || "false"
    // );
    // try to import the component
    componentImport()
      .then(component => {
        // window.sessionStorage.setItem("retry-lazy-refreshed", "false");
        resolve(component);
      })
      .catch(error => {
        // if (!hasRefreshed) {
        //   window.sessionStorage.setItem("retry-lazy-refreshed", "true");
        return window.location.reload();
        // }
        // reject(error); // Default error behaviour as already tried refresh
      });
  });
};
class App extends React.Component {
  componentDidMount() {
    ReactGA.initialize("UA-123001117-1");
    history.listen(location => {
      ReactGA.set({ page: location.pathname }); // Update the user's current page
      ReactGA.pageview(location.pathname); // Record a pageview for the given page
    });
    ReactGA.set({ page: history.location.pathname }); // Update the user's current page
    ReactGA.pageview(history.location.pathname); // Record a pageview for the given page

    // const ccSettings = new CometChat.AppSettingsBuilder()
    //   .setRegion("us")
    //   .build();
    // CometChat.init(process.env.REACT_APP_COMETCHAT_APPID, ccSettings);

    // This is a work around for an issue that is caused by React and users using Google Translate on the page.
    // Google Translate modifies the DOM which causes React to have an inaccurate VDOM. The result is that the
    // removeChild and insertBefore functions will cause errors.
    // As of right now, this bug has not been fix by the React people or the Google Translate people. I have 
    // implemented two of the work arounds that are suggested in this Github issue: 
    // https://github.com/facebook/react/issues/11538.
    // This is one of the work arounds. The other work around is to wrap text in a <span> element so React tracks
    // the node properly. I have added the spans to the two areas that were causing the White Screen of Deaths:
    // recent-payments.jsx:624 and BoxForm.jsx:39
    if (typeof Node === 'function' && Node.prototype) {
      const originalRemoveChild = Node.prototype.removeChild;
      Node.prototype.removeChild = function(child) {
        if (child.parentNode !== this) {
          if (console) {
            console.error('Cannot remove a child from a different parent', child, this);
          }
          return child;
        }
        return originalRemoveChild.apply(this, arguments);
      }
    
      const originalInsertBefore = Node.prototype.insertBefore;
      Node.prototype.insertBefore = function(newNode, referenceNode) {
        if (referenceNode && referenceNode.parentNode !== this) {
          if (console) {
            console.error('Cannot insert before a reference node from a different parent', referenceNode, this);
          }
          return newNode;
        }
        return originalInsertBefore.apply(this, arguments);
      }
    }
  }

  render() {
    return (
      <AppErrorBoundary>
        <StyletronProvider value={engine}>
          <BaseProvider theme={LightTheme}>
            <LoaderSquares />
            <Suspense fallback={<LoaderSquares loading={true} />}>
              <Router history={history}>
                <ScrollToTop>
                  <Switch>
                    <AuthRoute
                      isAuthenticated={true}
                      admin={true}
                      path="/admin"
                      component={AdminLayout}
                    />
                    <AuthRoute
                      isAuthenticated={true}
                      admin={true}
                      path="/partners"
                      component={PartnerLayout}
                    />
                    <AuthRouteParent
                      isAuthenticated={true}
                      path="/user"
                      component={ParentLayout}
                    />
                    <AuthRoute
                      isAuthenticated={true}
                      path="/educators"
                      component={EducatorLayout}
                    />
                    <AuthRouteParent
                      isAuthenticated={true}
                      path={"/programs/:slug/cart"}
                      exact={true}
                      component={Cart}
                    />
                    <RoutePublic
                      isAuthenticated={false}
                      path={"/programs/:slug/cart/checkout"}
                      component={CartCheckout}
                    />
                    <RoutePublic
                      isAuthenticated={false}
                      path="/"
                      exact
                      component={Home}
                    />
                    <RoutePublic
                      isAuthenticated={false}
                      path="/programs/:slug/virtual/:id"
                      exact
                      component={SpecificOnline}
                    />
                    <RoutePublic
                      isAuthenticated={false}
                      path="/become-an-instructor"
                      exact
                      component={becomeAnInstructor}
                    />
                    <RoutePublic
                      isAuthenticated={false}
                      path="/about"
                      exact
                      component={About}
                    />
                    <ConsoleRoute
                      isAuthenticated={false}
                      path="/organization/update/:access_key"
                      exact
                      component={PublicOrgUpdate}
                    />
                    <RoutePublic
                      isAuthenticated={false}
                      path="/privacy-policy"
                      exact
                      component={Privacy}
                    />
                    <RoutePublic
                      isAuthenticated={false}
                      path="/terms-of-use"
                      exact
                      component={Terms}
                    />
                    <RoutePublic
                      isAuthenticated={false}
                      path="/preview/:preview"
                      exact
                      component={Educator}
                    />
                    <RoutePublic
                      isAuthenticated={false}
                      path="/programs/:slug"
                      exact
                      component={PreviewPartner}
                    />
                    <RoutePublic
                      isAuthenticated={false}
                      path="/programs/:slug/program/:id"
                      exact
                      component={SpecificProgram}
                    />
                    <RoutePublic
                      isAuthenticated={false}
                      path="/programs/:slug/promotion/:id/"
                      exact
                      component={PartnerPromotionsDetail}
                    />
                    <RoutePublic
                      isAuthenticated={false}
                      path="/programs/:slug/membership/:id"
                      exact
                      component={SpecificMembership}
                    />
                    <RoutePublic
                      isAuthenticated={false}
                      path="/programs/:slug/event/:id"
                      exact
                      component={SpecificEvent}
                    />
                    <RoutePublic
                      isAuthenticated={false}
                      path="/programs/:slug/party/:id"
                      exact
                      component={SpecificParty}
                    />
                    <RoutePublic
                      isAuthenticated={false}
                      path="/programs/:slug/news/:id"
                      exact
                      component={SpecificNewsUpdates}
                    />
                    <RoutePublic
                      isAuthenticated={false}
                      path="/schools/:slug"
                      exact
                      component={Educator}
                    />
                    <RoutePublic
                      isAuthenticated={false}
                      path="/kids/:slug/:preview?"
                      exact
                      component={KidsPreview}
                    />
                    <AuthRouteParent
                      isAuthenticated={true}
                      iFrame={true}
                      path={"/iframe/programs/:slug/cart"}
                      exact={true}
                      component={Cart}
                    />
                    <RoutePublic
                      isAuthenticated={false}
                      iFrame={true}
                      path={"/iframe/programs/:slug/cart/checkout"}
                      component={CartCheckout}
                    />
                    <RoutePublic
                      isAuthenticated={false}
                      path="/iframes/forms/:slug/:id"
                      iFrame={true}
                      exact
                      component={FormIframeLoader}
                    />
                    <RoutePublic
                      isAuthenticated={false}
                      path="/forms/:slug/:email/:form/:id"
                      exact
                      component={FormPage}
                    />
                    <RoutePublic
                      isAuthenticated={false}
                      path="/iframes/programs/:slug"
                      iFrame={true}
                      exact
                      component={ProgramIframe}
                    />
                    <RoutePublic
                      isAuthenticated={false}
                      path="/iframe/programs/:slug/program/:id"
                      exact
                      iFrame={true}
                      component={SpecificProgram}
                    />
                    <RoutePublic
                      isAuthenticated={false}
                      path="/iframe/programs/:slug/membership/:id"
                      exact
                      iFrame={true}
                      component={SpecificMembership}
                    />
                    <RoutePublic
                      isAuthenticated={false}
                      path="/iframe/programs/:slug/event/:id"
                      exact
                      iFrame={true}
                      component={SpecificEvent}
                    />
                    <RoutePublic
                      isAuthenticated={false}
                      path="/iframe/programs/:slug/party/:id"
                      exact
                      iFrame={true}
                      component={SpecificParty}
                    />
                    <RoutePublic
                      isAuthenticated={false}
                      path="/iframe/programs/:slug/online/:id"
                      exact
                      iFrame={true}
                      component={SpecificOnline}
                    />
                    <RoutePublic
                      isAuthenticated={false}
                      path="/iframe/programs/:slug/news/:id"
                      exact
                      iFrame={true}
                      component={SpecificNews}
                    />

                    <RoutePublic
                      isAuthenticated={false}
                      path="/search"
                      exact
                      component={Search}
                    />
                    <RoutePublic
                      isAuthenticated={false}
                      path="/search/:q"
                      exact
                      component={Search}
                    />
                    <RoutePublic
                      isAuthenticated={false}
                      path="/learn-more"
                      exact
                      component={LearnMore}
                    />
                    <RoutePublic
                      isAuthenticated={false}
                      path="/ttc"
                      exact
                      component={Search}
                    />
                    <RoutePublic
                      isAuthenticated={false}
                      path="/promo"
                      exact
                      component={Search}
                    />
                    <RoutePublic
                      isAuthenticated={false}
                      path="/find"
                      exact
                      component={Search}
                    />
                    <RoutePublic
                      isAuthenticated={false}
                      path="/daybear"
                      exact
                      component={Search}
                    />
                    <RoutePublic
                      isAuthenticated={false}
                      path="/kijiji"
                      exact
                      component={Search}
                    />
                    <RoutePublic
                      isAuthenticated={false}
                      path="/igdiscover"
                      exact
                      component={Search}
                    />
                    <RoutePublic
                      isAuthenticated={false}
                      path="/gdiscover"
                      exact
                      component={Search}
                    />
                    <RoutePublic
                      isAuthenticated={false}
                      path="/fbdiscover"
                      exact
                      component={Search}
                    />
                    <RoutePublic
                      isAuthenticated={false}
                      path="/partner"
                      exact
                      component={Partner}
                    />
                    <RoutePublic
                      isAuthenticated={false}
                      path="/provide"
                      exact
                      component={Partner}
                    />
                    <RoutePublic
                      isAuthenticated={false}
                      path="/providers"
                      exact
                      component={Partner}
                    />
                    <RoutePublic
                      isAuthenticated={false}
                      path="/sign-in"
                      exact
                      component={Login}
                    />
                    <RoutePublic
                      isAuthenticated={false}
                      path="/partner/sign-in"
                      exact
                      component={PartnersLogin}
                    />
                    <RoutePublic
                      isAuthenticated={false}
                      path="/forgot-password"
                      exact
                      component={ForgotPassword}
                    />
                    <RoutePublic
                      isAuthenticated={false}
                      path="/register"
                      exact
                      component={Signup}
                    />
                    <RoutePublic
                      isAuthenticated={false}
                      path="/reset-password/:token"
                      exact
                      component={ResetPassword}
                    />
                    <RoutePublic
                      isAuthenticated={false}
                      path="/orders-receipt/:id/:isDeposit"
                      exact
                      component={OrderReceipt}
                    />
                    <RoutePublic
                      isAuthenticated={false}
                      path="/custom-receipt/:id/:email"
                      exact
                      component={CustomReceipt}
                    />
                    <RoutePublic
                      isAuthenticated={false}
                      path="/invoice/:id/:email/:isDeposit"
                      exact
                      component={InvoiceCheckoutPublic}
                    />
                    <RoutePublic
                      isAuthenticated={false}
                      path="/invoice/:id/:email/"
                      exact
                      component={InvoiceCheckoutPublic}
                    />
                    <RoutePublic
                      isAuthenticated={false}
                      path="/invoice/:id/:isDeposit"
                      exact
                      component={InvoiceCheckoutPublic}
                    />

                    <Route component={NotFound} />
                  </Switch>
                </ScrollToTop>
              </Router>
            </Suspense>
            <ToastContainer
              position="bottom-right"
              autoClose={2000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnVisibilityChange
              draggable
              pauseOnHover
            />
          </BaseProvider>
        </StyletronProvider>
      </AppErrorBoundary>
    );
  }
}

export default App;
