import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import { getSelectedProgramPlan } from "./utils";
const ProgramDropInsSubtotal = props => {
  const [subtotal, setSubtotal] = useState("Loading");

  const calculateSubtotal = () => {
    const plan = getSelectedProgramPlan(props.program, props.selected);
    const amnt = (plan.total_price * props.selected_days_length).toFixed(2);
    props.itemAmount(amnt);
    setSubtotal(amnt);
  };

  useEffect(() => {
    calculateSubtotal();
  }, []);
  return (
    <li
      style={{
        display: "flex",
        justifyContent: "space-between",
        padding: "15px 10px",
        borderBottom: "1px solid #dedede"
      }}
    >
      <p
        style={{
          marginBottom: 0,
          fontFamily: "Open Sans, sans-serif",
          fontWeight: 500,
          fontSize: "14px"
        }}
      >
        Subtotal
      </p>
      <span style={{ fontWeight: 500 }}>${subtotal}</span>
    </li>
  );
};

ProgramDropInsSubtotal.propTypes = {
  program: PropTypes.shape({
    plans: PropTypes.array.isRequired
  }).isRequired,
  selected: PropTypes.number.isRequired,
  selected_days_length: PropTypes.number.isRequired
};

export default ProgramDropInsSubtotal;
