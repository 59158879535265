import React from "react";
import PropTypes from "prop-types";
import GoogleMapReact from "google-map-react";

import { OrangePin } from "../../../assets";

const MembershipDescription = ({ membership }) => {
  return (
    <>
      <div className="box-size">
        <h3 className="sub-title">Membership Description</h3>
        <p
          style={{
            fontWeight: "400",
            fontFamily: `"Open sans", sans-serif`
          }}
        >
          {membership.description}
        </p>
      </div>

      <div className="box-size">
        <h3 className="sub-title">Additional Perks</h3>
        <p
          style={{
            fontWeight: "400",
            fontFamily: `"Open sans", sans-serif`
          }}
        >
          {membership.additional_perks}
        </p>
      </div>

      <div className="box-size">
        <h3 className="sub-title">Location</h3>
        <div className="map-size">
          <GoogleMapReact
            bootstrapURLKeys={{
              key: "AIzaSyDVNuWNguQz4vB25OdDpztwxE3QR_CzG0g"
            }}
            defaultZoom={9}
            defaultCenter={{ lat: 43.695572, lng: -79.415605 }}
            options={{ gestureHandling: "greedy" }}
          >
            <div
              lat={membership.main_lat}
              lng={membership.main_long}
              style={{
                backgroundImage: `url(${OrangePin})`,
                backgroundSize: "cover",
                height: 30,
                width: 22,
                cursor: "pointer",
                zIndex: 10,
                position: "relative",
                bottom: 15,
                right: 15
              }}
            ></div>
          </GoogleMapReact>
        </div>
      </div>
    </>
  );
};

MembershipDescription.propTypes = {
  membership: PropTypes.object.isRequired
};

export default MembershipDescription;
