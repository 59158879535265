import React, { useState, useEffect, useGlobal } from "reactn";

import PropTypes from "prop-types";

import { promotion_discount, getSelectedProgramPlan } from "./utils";
const ProgramDropinsPromotionsTotal = props => {
  const [total, setTotal] = useState("Loading");
  const [_, setPreGiftTotal] = useGlobal("preGiftTotal");
  const giftBalance = useGlobal("giftBalance")[0];

  const calculate = () => {
    const plan = getSelectedProgramPlan(props.program, props.selected);
    const total_price = parseFloat(plan.total_price);
    const subtotal = total_price * props.selected_days_length;
    const discount_amount = promotion_discount(props.promotion, subtotal);
    const taxRate = plan.isTaxEnabled
      ? parseFloat(plan.taxInfo[0].percentage / 100)
      : 0;
    const tax_amount = Math.max(0, subtotal - discount_amount) * taxRate;

    // credit card processing fee
    const creditPercent = props.program.transaction_cost
      ? parseFloat(1.5) / 100
      : 1;
    const creditCardFee = props.program.transaction_cost
      ? Math.max(0.5, subtotal - discount_amount) * creditPercent
      : 0;
    // const creditCardFee = 0;

    let totalAmnt =
      Math.max(0.5, subtotal - discount_amount) + tax_amount + creditCardFee;

    setPreGiftTotal(totalAmnt);
    totalAmnt = giftBalance > totalAmnt ? 0 : totalAmnt - giftBalance;
    props.totalAmount(totalAmnt.toFixed(2));
    setTotal(totalAmnt.toFixed(2));
  };

  useEffect(() => {
    calculate();
  }, [giftBalance]);

  return (
    <li
      style={{
        display: "flex",
        justifyContent: "space-between",
        padding: "15px 10px"
      }}
    >
      <p
        style={{
          marginBottom: 0,
          fontFamily: "Open Sans, sans-serif",
          fontWeight: 600
        }}
      >
        Total
      </p>
      <span style={{ fontWeight: "bold" }}>${total}</span>
    </li>
  );
};

ProgramDropinsPromotionsTotal.propTypes = {
  promotion: PropTypes.object.isRequired,
  program: PropTypes.shape({
    plans: PropTypes.array.isRequired
  }).isRequired,
  selected: PropTypes.number.isRequired,
  selected_days_length: PropTypes.number.isRequired
};

export default ProgramDropinsPromotionsTotal;
