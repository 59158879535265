import { setGlobal, addReducer } from "reactn";
import axios from "axios";

const cart = {};

setGlobal({ cart });

// Currently, adding to cart is done in the partnerSpecific{Program / Online / etc}.jsx component.
// TODO: migrate the logic to use this reducer instead.
// addReducer("addToCart", async (global, dispatch, item, data) => {
//   console.log("addToCart", item, data);
//   const addToCart = async (item) => {
//     const ep = `${process.env.REACT_APP_API}/par/cart`;
//     const res = await axios.put(ep, data);
//     if (res.data.success) {
//       const newCart = {
//         ...global.cart,
//         [item.slug]: {
//           ...global.cart?.[item.slug],
//           items: [
//             ...global.cart[item.slug]?.items,
//             {
//               image: item.image,
//               name: item.name,
//               type: item.type,
//               price: item.price,
//               quantity: item.quantity,
//             }
//           ],
//           name: item.organization_title
//         }
//       };
//
//       return {
//         ...global,
//         cart: newCart,
//         lastAPICall: res
//       };
//     }
//   }
//   await addToCart(item);
// });

/**
 * Handles getting a cart from a single partner.
 * @param {{partnerId: number?, partnerSlug: string?}} params - One of the two parameters is required to identify which partner to get the cart for.
 */
addReducer("getCart", async (global, dispatch, params) => {
  try {
    let ep = `${process.env.REACT_APP_API}/par/cart/all`;
    const res = await axios.get(ep);
    const cart = res.data.data;
    console.log("cart", cart);
    const newCart = cart.reduce((obj, curr) => {
      return {
        ...obj,
        [curr.slug]: {
          name: curr.name,
          items: curr.items.map((item) => {
            const formatItem = (item) => {
              let plan;
              switch (item.productTable) {
                case "partner_programs":
                case "partner_online":
                  plan = JSON.parse(item.product.plans.filter(plan => JSON.parse(plan).id === item.planId)[0]);
                  return {
                    cartId: item.id,
                    planId: plan.id,
                    name: item.product.program_name,
                    price: plan.tuition_rate || plan.total_price || plan.price_per_term || plan.ticket_price || 0,
                    image: item.product.program_photo,
                    quantity: item.quantity,
                    type: item.product.program_details || "Online"
                  }
                case "partner_event":
                  plan = JSON.parse(item.product.tickets.filter(plan => JSON.parse(plan).id === item.planId)[0]);
                  return {
                    cartId: item.id,
                    planId: plan.id,
                    name: item.product.event_title,
                    price: plan.ticket_price || 0,
                    image: item.product.event_image,
                    quantity: item.quantity,
                    type: "Event"
                  }
                case "partner_memberships":
                  plan = JSON.parse(item.product.prices.filter(plan => JSON.parse(plan).id === item.planId)[0]);
                  return {
                    cartId: item.id,
                    planId: plan.id,
                    name: item.product.membership_name,
                    price: plan.price_per_term || 0,
                    image: item.product.membership_image === 2 ? item.product.membership_image_url : item.product.membership_image,
                    quantity: item.quantity,
                    type: "Membership"
                  }
              }
            }
            return formatItem(item);
          })
        }
      }
    }, {});
    console.log("newCart", newCart);

    return {
      ...global,
      loading: false,  //sometimes global.loading is true to start, becomes false before reaching this point, and inadvertently gets set back to true because we're holding an old version of "global".
      cart: newCart,
      lastAPICall: res,
    }
  } catch (err) {
    console.error(err.message);
    return { ...global, loading: false, lastAPICall: null };
  }
});

addReducer("updateCart", async (global, dispatch, cart) => {

});

