import React from "react";
import PropTypes from "prop-types";
import { format } from "date-fns";
import { getSelectedProgramPlan } from "./utils";

const CheckoutDropInsSelectedDays = props => {
  const plan = getSelectedProgramPlan( props.program, props.selected );
  return (
    <>
      {props.selected_days.map(e => (
        <li
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: "15px 10px",
            borderBottom: "1px solid #dedede"
          }}
        >
          <p
            style={{
              marginBottom: 0,
              fontFamily: "Open Sans, sans-serif",
              fontWeight: 500,
              fontSize: "14px"
            }}
          >
            Drop-in - {format(new Date(e), "LLLL dd, yyyy")}
          </p>
          <span style={{ fontWeight: "bold" }}>
            ${" "}
            {parseFloat(plan.total_price).toFixed(2)}
          </span>
        </li>
      ))}
    </>
  );
};

CheckoutDropInsSelectedDays.propTypes = {
  selected_days: PropTypes.array.isRequired,
  program: PropTypes.object,
  selected: PropTypes.number
};

export default CheckoutDropInsSelectedDays;
