import { DateTime } from "luxon";


export const RXP_INTEGER = /^\d+$/;

export const testIsInteger = (val) => RXP_INTEGER.test(val);





/**
 * RegExp that looks for a "yyyy-mm-dd" date followed by "T" or space.
 */
const REGEXP_ISO_DATE = /^\d\d\d\d-\d\d-\d\d(?=( |T|$))/;

/**
 * This function below only looks at the "yyyy-mm-dd" portion of a date string, and ignores any
 * any time or timezone component. Best to only use it with UTC+0 time!
 */
export function constructDateOnlyWithTimeZone( dateStr, ianaTimezone ) {
    let rxpResult = REGEXP_ISO_DATE.exec(dateStr);
    if (dateStr) {
      console.assert( rxpResult, `constructDateOnlyWithTimeZone(${JSON.stringify(dateStr)}) expecting a null or a string that is in yyyy-mm-dd format.` );
    }

    //This part below was tested with Luxon v1.28.0 and v2.3.0.
    //if ISO value found (rxpResult=true), then "2022-01-18" becomes 2022-01-18 00:00:00 in the provided timezone!
    //else, it becomes the current date on the local machine, time 00:00:00, and in the provided timezone!
    let dateOnlyWithTZ = ( rxpResult ? DateTime.fromISO(rxpResult[0]) : DateTime.local() )
                            .setZone(ianaTimezone, {keepLocalTime:true})
                            .set({hour:0,minute:0,second:0,millisecond:0});
    let formattedDate = dateOnlyWithTZ.toLocaleString( DateTime.DATE_FULL );
    return { dateOnlyWithTZ, formattedDate };
}


/**
 * calling this function with
 * (startTimeDec=10.5, endTimeDec=12.25, dateOnlyWithTZ=DateTime("2022-01-12"))
 * will return "10:30 AM - 12:15 PM EST". And if that date is in daylight savings, then EDT.
 */
export function constructTimeRangeFromDecimalHours( startTimeDec, endTimeDec, dateOnlyWithTZ ) {
  if (!dateOnlyWithTZ) dateOnlyWithTZ = DateTime.now();   //setting this as the default value for an optional parameter doesn't work if that parameter is explicitly set to null!

  console.assert( dateOnlyWithTZ instanceof DateTime, "dateOnlyWithTZ parameter must be an instance of (Luxon) DateTime.")
  //dateOnlyWithTZ is immutable, so set() below returns a new object!
  //setting minute field wraps any extra into the hour field, eg. +61mins becomes +1hr+1min
  let startTime = dateOnlyWithTZ.set( {
    hour: Math.trunc(startTimeDec),
    minute: Math.round( (startTimeDec % 1) * 60 ),  //rounding should prevent .49999 becoming 29.99999mins
    second: 0,
    millisecond: 0
  } );
  let endTime = dateOnlyWithTZ.set( {
    hour: Math.trunc(endTimeDec),
    minute: Math.round( (endTimeDec % 1) * 60 ),  //rounding should prevent .49999 becoming 29.99999mins
    second: 0,
    millisecond: 0
  } );
  return {startTime,endTime};
}



/**
 * This is to be used with DateTime to format as: hh:mm am zzz
 */
const TIME_SIMPLE_WITH_OFFSET = { ...DateTime.TIME_SIMPLE, timeZoneName:"short" };

/**
 * Takes a startTime and endTime, both (Luxon)DateTime objects, and returns it as a simple formatted time range.
 * e.g. 5:00 PM - 7:00 PM EDT.
 * The only time it fucks up is if starting time and ending time fall into different timezones, eg. EDT vs EST.
 * @param {DateTime} startTime 
 * @param {DateTime} endTime 
 * @param {string} [separator=" - "]
 * @param {boolean} [hideTZ=false] for the few cases where you don't want to show timezone.
 * @returns {string}
 */
export function getFormattedTimeRange( startTime, endTime, separator=" - ", hideTZ=false) {
  let startTimeFormatted = startTime.toLocaleString(DateTime.TIME_SIMPLE);
  let endTimeFormatted = endTime.toLocaleString( hideTZ ? DateTime.TIME_SIMPLE : TIME_SIMPLE_WITH_OFFSET );
  //string comparison below is not as good, as actual date comparison.
  return endTimeFormatted.startsWith(startTimeFormatted)
      ? endTimeFormatted                                   //eg. 5:00 PM EDT
      : startTimeFormatted + separator + endTimeFormatted; //eg. 3:00 PM - 5:00 PM EDT
}



/**
 * This is to be used with DateTime to format as: hh:mm am zzz
 */
 const DATE_FULL_WITHOUT_YEAR = { month:"long", day:"numeric" };
 const DATE_MED_WITHOUT_YEAR = { month:"short", day:"numeric" };

/**
 * Takes a startDate and endDate, both (Luxon)DateTime objects, and returns it as a simple formatted date range.
 * e.g. Jan 8 - Jan 13, 2022
 * e.g. December 28, 2021 - January 3, 2022
 * @param {DateTime} startDate 
 * @param {DateTime|number} endDateOrDuration
 * @param {string} separator 
 * @returns 
 */
export function getFormattedDateRange( startDate, endDate, separator=" - ", shortMonth=false ) {

  //First, handle "endDate" if it's a number. ie. endDate = startDate + numeric_param(endDate)
  if (typeof endDate === "number")
    endDate = startDate.plus( {days: endDate} );
  else if (endDate instanceof Date)
    endDate = DateTime.fromJSDate(endDate);

  let startDateObj = startDate.toObject();
  let endDateObj = endDate.toObject();

  let isSameYear = (startDateObj.year === endDateObj.year);
  let isSameMonth = (startDateObj.month === endDateObj.month);
  let isSameDay = (startDateObj.day === endDateObj.day);
  let startDateFormat = shortMonth
                      ? (isSameYear ? DATE_MED_WITHOUT_YEAR : DateTime.DATE_MED)
                      : (isSameYear ? DATE_FULL_WITHOUT_YEAR : DateTime.DATE_FULL);

  let endDateFormat = shortMonth
                    ? DateTime.DATE_MED
                    : DateTime.DATE_FULL;

  let startDateFormatted = startDate.toLocaleString(startDateFormat);
  let endDateFormatted = endDate.toLocaleString(endDateFormat);

  return (isSameYear && isSameMonth && isSameDay )
      ? endDateFormatted                                 //eg. May 10, 2022
      : startDateFormatted + separator + endDateFormatted; //eg. May 10, 2022 - May 11, 2022
}



/**
 * This is to be used with DateTime to format as: MMM d, yyyy, hh:mm am zzz
 */
 const DATETIME_MED_WITH_OFFSET = { ...DateTime.DATETIME_MED, timeZoneName:"short" };

/**
 * Representations:
 *   Jan 20, 2021, 9:00 PM - 11:00 PM EST                    <-- start and end time are same day.
 *   Jan 20, 2021, 9:00 PM - Jan 21, 2021, 1:00 AM EST   <-- start and end time are different days
 *   NOT THIS! 9:00 PM Jan 20 - 1:00 AM Jan 21, 2021 EDT
 * @param {*} startDateTime
 * @param {*} endDateTime 
 * @param {*} separator 
 */
export function getFormattedDateTimeRange( startDateTime, endDateTime, separator=" - " ) {
  let startObj = startDateTime.toObject();
  let endObj = endDateTime.toObject();
  let isSameDay = (startObj.year === endObj.year) && (startObj.month === endObj.month) && (startObj.day === endObj.day);

  let startDateTimeFormatted = startDateTime.toLocaleString( DateTime.DATETIME_MED );  //eg. "Jan 20, 2021, 9:00 PM"
  let endDateTimeFormatted =  endDateTime.toLocaleString( isSameDay ? TIME_SIMPLE_WITH_OFFSET : DATETIME_MED_WITH_OFFSET ); //either "11:00 PM EST" or "Jan 21, 2021, 1:00 AM EST"

  return startDateTimeFormatted + separator + endDateTimeFormatted;
}





const DAYS_OF_WEEK_ORDERED = Object.freeze(["Sunday","Monday","Tuesday","Wednesday","Thursday","Friday","Saturday"]);

/**
 * Takes a dictionary/object of weekday names and makes a pretty string out of it.
 * Example: {Monday:true,Tuesday:true,Wednesday:true}  ==>  Mon-Wed
 * @param {Object} selectedDaysObj  a dictionary/object of weekday names as keys, and true/false as values.
 */
export function getGroupedWeekdayRange( selectedDaysObj, useLongNames ) {

  const nameLength = (useLongNames ? 999 : 3);
  //Below, it creates a 7-element array with each selected day in its position (first 3 chars), or a null.
  //e.g. if selectedDaysObj={Monday:true,Wednesday:true,Thursday:true}, then selectedDaysArr=[null,Mon,null,Wed,Thu,null,null].
  let selectedDaysArr = DAYS_OF_WEEK_ORDERED.map( day => selectedDaysObj[day] ? day.substring(0,nameLength) : null );

  let groupedDaysStr = selectedDaysArr
                        .join(",")
                        .replace(/(\w)(,\w+)+,(?=\w)/g, "$1-")  //e.g. "Tue,Wed,Thu,Fri" => "Tue-Fri"
                        .replace(/^,+/,"")          //remove extra commas from beginning
                        .replace(/,+$/,"")          //remove extra commas from ending
                        .replace(/,+/g,", ");     //remove multiple consecutive commas
  //console.log(groupedDaysStr);
  return groupedDaysStr;
}












const orderedListOfDaysOfWeek = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday"
];

//NOTE! This function only returns results in the local timezone, so as to make it easier to render on the local browser!
//      Do not use if you need the schedule to be timezone precise!
export function buildScheduleForProduct( isoStartDate, durationInDays, days_of_week, startHour, endHour, closureDatesArr ) {
  //NOTE: "isoStartDate" parameter must be yyyy-mm-dd string! ie. NO TIMEZONE!!
  let curDate = new Date( isoStartDate + "T00:00:00" );   //"curDate" will be in local time (hour=0, min=0, sec=0), not UTC time!!
  let resultsArr = [];
  let seriesFinalDate;
  const hasNoDuration = (!durationInDays);   //if duration = 0, null, or undefined.

  const closureDatesLT = (!closureDatesArr || closureDatesArr.length===0)
                        ? {}
                        : Object.fromEntries( closureDatesArr.map(d=>[d.valueOf(),true]) );

  do {
    if (hasNoDuration                                    //this is so loop executes only once, even if days_of_week is null/empty!
      || (days_of_week[orderedListOfDaysOfWeek[curDate.getDay()]]    //check if curDate is in one of the days of the week.
          && !closureDatesLT[curDate.valueOf()])                      //checks if this is one of the closure dates
    ) {
      const startDateTime = new Date( curDate );   //Date is mutable! setHours() will change it!
      startDateTime.setHours( 0, Math.round(startHour * 60), 0, 0 );
      const endDateTime = new Date( curDate );   //Date is mutable! setHours() will change it!
      endDateTime.setHours( 0, Math.round(endHour * 60), 0, 0 );

      resultsArr.push( {hasNoDuration, startDateTime, endDateTime} );
      seriesFinalDate = new Date(curDate);  //the final final date will be returned at end of this function.
    }
    curDate.setDate( curDate.getDate() + 1 );   //increment by one day! This should work in any timezone!
  } while (--durationInDays>0)

  return { schedules:resultsArr, seriesFinalDate };
};


export function prepareClosureDates( closure_dates, closure_timeframe ) {
  // console.log("closure_dates:",closure_dates);
  // console.log("closure_timeframe:",closure_timeframe);

  let retval = (closure_dates || [])
    .map( mmmm_d_yyyy_form_date => {
      let d = new Date(mmmm_d_yyyy_form_date);
      if (isNaN(d.valueOf()))
        throw new RangeError("one of the closure_dates is invalid!");
      d.setHours(0,0,0,0);     //"mmmm d, yyyy" format is supposed to ensure no time component, but this is just in case!
      return d;
    });

  if (closure_timeframe
    && (closure_timeframe[0] || closure_timeframe[1])
    && closure_timeframe.length>=0 && closure_timeframe.length<=2   //Can only handle arrays of 0 to 2 time values.
  ) {
    let startDate = new Date( closure_timeframe[0] || closure_timeframe[1] );
    let endDate = new Date( closure_timeframe[1] || closure_timeframe[0] );
    if ( isNaN(startDate.valueOf()) || isNaN(startDate.valueOf()) )
      throw new RangeError("one of the closure_timeframe date values is invalid!");

    startDate.setHours(0,0,0,0);  //just in case!
    endDate.setHours(0,0,0,0);  //just in case!

    for (let d=new Date(startDate);  d<=endDate;  d.setDate(d.getDate()+1) ) {
      retval.push( new Date(d) );    //"d" will mutate throughout this loop, so create a new Date instance.
    }
  }
  return retval;    //results don't need to be sorted! The next step in the pipeline (ie. buildScheduleForProduct)
                    //will create a quick lookup table with these values.
};


/**
 * Takes the "originalUtcDateTime" value, as read from DB,
 * sets it to the "originalTimeZone" as specified by the partner,
 * and then keeps the date and time, but sets timezone to the browser's local TZ.
 * This is so an event/program created for EST/EDT customers will still look
 * consistent when viewed in any other area of the world!
 * @param {string} originalUtcDateTime - an ISO formatted datetime string
 * @param {string} originalTimeZone - an IANA timezone.
 * @returns {Date}
 */
export function makeUTCDateTimeAsLocalTZ( originalUtcDateTime, originalTimeZone ) {
  // console.log(`============ makeUTCDateTimeAsLocalTZ: [${originalUtcDateTime}], [${originalTimeZone}]`);
  let a = DateTime.fromISO( originalUtcDateTime );
  let b = a.setZone( originalTimeZone );
  let c = b.setZone( "local", {keepLocalTime:true} );
  // console.log("     a:",a);
  // console.log("     b:",b);
  // console.log("     c:",c.toJSDate());
  return c.toJSDate();
};
