import React from "reactn";
import { withRouter } from "react-router-dom";
import "./ProgramSearchInput.css";
import { SearchIcon } from "../../assets";
import Geosuggest from "react-geosuggest";
import axios from "axios";
import { toast } from "react-toastify";

class SearchBoxData {
  static apiCallTime = null;
  static resultDataPromise = null;

  static async getAllNames() {
    if (Date.now() - SearchBoxData.apiCallTime < 3600000)    //has it been less than 1hr since last api call?
      return SearchBoxData.resultDataPromise;

    SearchBoxData.apiCallTime = Date.now();
    SearchBoxData.resultDataPromise = axios.get( `${process.env.REACT_APP_API}/search` )
      .then((result)=>{
        //console.log(`result[]:`, result);
        if (!result?.data?.success)
          throw new Error(`Unable to get search box auto-complete data. Error=[${result?.data?.message || result?.statusText}]`);
        return (result?.data?.data || []);
      })
      .catch((ex)=>{
        console.error(`ProgramSearchInput.jsx: exception during GET /search :`,ex);
        toast.error(`Search box might not work!`, { autoClose: 10000 });
        return [];
      });
    return SearchBoxData.resultDataPromise;
  }
}

class ProgramSearchInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      q: props.value || "",
      locationBias: this.getLatLng(),
      searchByAddress: true,
//      organizations: [],
      organizationSearch: "",
      focused: false,
      schools: []
    };
    this._geoSuggest = null;
    this.setAddress = this.setAddress.bind(this);
  }

//  getPinColor = school => {
//    return school.programCoverPhoto
//      ? "red"
//      : school.clickable
//      ? "red"
//      : school.isLive
//      ? "orange"
//      : "grey";
//  };

//  getUrl = school => {
//    return school.slug
//      ? school.clickable
//        ? `/programs/${school.slug}`
//        : school.isLive
//        ? `/kids/${school.slug}`
//        : school.progress !== 0 &&
//          school.progress !== 1 &&
//          school.progress !== 2 &&
//          school.progress !== 4 &&
//          school.progress !== 5 &&
//          `/schools/${school.slug}`
//      : `#/`;
//  };


  async componentDidMount() {
    SearchBoxData.getAllNames()
      .then( (result) => {
        this.setState({ schools: result });
      })
      .catch( (ex) => {
        console.log(ex);
      });

  };


  handleSubmit = e => {
    e.preventDefault();
    if (!this.props.location.pathname === "/register")
      this.props.history.push(`/search/${this.state.q}`);
  };

  setAddress = e => {
    if (e?.location?.lat === -1 && e?.location?.lng === -1) {
      this.setState({ searchByAddress: false });
      return;
    }
    if (e && e.gmaps) {
      try {
        this.setState({
          q: e.gmaps.formatted_address.split(",")[0],
          lat: e.location.lat,
          lng: e.location.lng
        });
        if (!this.props.noicon)
          if (this.props.location.pathname !== "/register") {
            this.props.history.push(
              `/search/${this.state.lat},${this.state.lng}`
            );
            this.props.shrinknav();
          }

        if (this.props.gLoc)
          this.props.gLoc(e.gmaps.formatted_address, e.location);
      } catch (ex) {}
    } else {
      this.setState({ q: e });
      if (this.props.gLoc) if (!this.props.blueborder) this.props.gLoc(e);
    }
  };

  getLatLng() {
    try {
      const locationBias = this.props.locationBias;
      if (locationBias && locationBias.lat && locationBias.long) {
        return new window.google.maps.LatLng(locationBias.lat, locationBias.long);
      }
      //hard-coded values below should eventually be moved elsewhere.

      return new window.google.maps.LatLng(43.6499722, -79.3997312);
    } catch (ex) {
      return { lat: 43.6499722, lng: -79.3997312 };
    }
  }

  getOrganizationOptions() {
    if (!this.state.schools) return [];
    const result = this.state.schools.filter(
      org =>
        org.name
          .toLowerCase()
          .substr(0, this.state.organizationSearch.length) ===
        this.state.organizationSearch.toLowerCase()
    );
    return result.slice(0, 6);
    // const result = this.state.organizations.filter(
    //   org =>
    //     (org.program_name || org.programName)
    //       .toLowerCase()
    //       .substr(0, this.state.organizationSearch.length) ===
    //     this.state.organizationSearch.toLowerCase()
    // );
    // const temp = [...result];
    // return temp.reverse();
  }

  render() {
    return (
      <div
        className={
          this.props.noicon
            ? this.props.blueborder
              ? `n-search-blue`
              : `n-search-reg`
            : "n-search-container"
        }
      >
        <div className={`n-search-header`} style={this.props.style || {}}>
          <form
            className="n-search-main w-100"
            style={{ position: "relative" }}
          >
            {this.state.searchByAddress ? (
              <Geosuggest
                onSuggestSelect={this.setAddress}
                onChange={this.setAddress}
                initialValue={this.state.q}
                placeholder="Search by address or zipcode"
                ref={el => (this._geoSuggest = el)}
                location={this.state.locationBias}
                country={"ca"/*restrict searches to Canada only!*/}
                radius={30}
                id={this.props.name ? this.props.name : "geo"}
                autoComplete={"off"}
                fixtures={[
                  {
                    label: "Search by organization",
                    location: { lat: -1, lng: -1 }
                  }
                ]}
                // location={new google.maps.LatLng(53.558572, 9.9278215)}
                // radius="20"
              />
            ) : (
              <div
                style={{
                  position: "relative"
                }}
              >
                <input
                  type="text"
                  onChange={e =>
                    this.setState({ organizationSearch: e.target.value })
                  }
                  placeholder="Search by organization name"
                  onFocus={() => this.setState({ focused: true })}
                  onBlur={() =>
                    setTimeout(() => this.setState({ focused: false }), 100)
                  }
                  value={this.state.organizationSearch}
                />
                {this.state.organizationSearch === "" ? (
                  this.state.focused ? (
                    <div
                      className="organization-search organization-search-item"
                      onClick={() => this.setState({ searchByAddress: true })}
                    >
                      Search by location
                    </div>
                  ) : null
                ) : this.getOrganizationOptions().length > 0 ? (
                  <div className="organization-search">
                    {this.getOrganizationOptions().map(org => (
                      <div
                        className="organization-search-item"
                        onClick={() => {
                          // this.props.history.push(`/${org.path}/${org.slug}`);
                          // this.props.history.push(this.getUrl(org));
                          this.props.history.push(org.link);
                          this.setState({ organizationSearch: "" });
                        }}
                      >
                        {/* {org.program_name || org.programName} */}
                        {org.name}
                      </div>
                    ))}
                  </div>
                ) : null}
              </div>
            )}
            {!this.props.noicon && (
              <button
                type="submit"
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  marginRight: "10px",
                  width: "13%",
                  zIndex: 9999999
                }}
                disabled={true}
                onClick={this.handleSubmit}
              >
                <img src={SearchIcon} alt="Search" />
              </button>
            )}
          </form>
        </div>
      </div>
    );
  }
}

export default withRouter(ProgramSearchInput);
